import { ChatIFrame } from './ChatIFrame';
import { NotifyEmitter } from './NotifyEmitter';
import { ChatSettings } from './Settings';

export class InlineChat implements NotifyEmitter {
  private chat?: ChatIFrame;

  constructor(id: string, origin: string, chatSettings: ChatSettings, containerId: string) {
    const container = document.getElementById(containerId);

    if (container) {
      this.chat = new ChatIFrame(id, origin, chatSettings, container);
    }
  }

  public notify(message: string | object, origin?: string) {
    if (this.chat) {
      this.chat.notify(message, origin);
    }
  }

  public dispose() {
    if (this.chat) {
      this.chat.dispose();
    }

    this.chat = undefined;
  }
}
