import { ChatLauncher } from './ChatLauncher';
import { Config } from './config/config';
import { DeviceHelper } from './DeviceHelper';
import { Modal } from './Modal';
import { NotifyEmitter } from './NotifyEmitter';
import { Display, LauncherSettings, SDKSettings } from './Settings';

const NATIVECHAT_CONTAINER_ID = 'native-chat-container';

export class ModalChat implements NotifyEmitter {
  private modal?: Modal;
  private tabOnMobileDevice?: Window;
  private launcher?: ChatLauncher;

  constructor(sdkSettings: SDKSettings, launcherSettings?: LauncherSettings) {
    this.modal = new Modal(sdkSettings.id, sdkSettings.origin, sdkSettings.chat);

    const isMobile = DeviceHelper.isMobile();

    this.launcher = new ChatLauncher(
      sdkSettings.id,
      () => this._launcherOpenHandler(isMobile, sdkSettings),
      () => this._launcherCloseHandler(isMobile),
      launcherSettings);
  }

  public notify(message: string | object, origin?: string) {
    if (this.modal) {
      this.modal.notify(message, origin);
    }
  }

  public dispose() {
    if (this.launcher) {
      this.launcher.dispose();
    }

    this.launcher = undefined;

    if (this.modal) {
      this.modal.dispose();
    }

    this.modal = undefined;
  }

  private _launcherOpenHandler(isMobile: boolean, sdkSettings: SDKSettings) {
    if (!isMobile) {
      return this.modal && this.modal.open();
    }

    const settings = JSON.parse(JSON.stringify(sdkSettings));

    settings.display = {
      containerId: NATIVECHAT_CONTAINER_ID,
      mode: Display.Inline,
    };

    const scrollContainerToClientAreaCenterRemediationScriptiOS = DeviceHelper.isiOS() ? `
    if (window.visualViewport) {            
      function resizeHandler() {
          const nativeChatContainer = document.getElementById("${NATIVECHAT_CONTAINER_ID}");
          document.getElementsByTagName("html")[0].style.height = window.visualViewport.height.toString() + "px";
          
          nativeChatContainer.scrollIntoView(false);
      }

      window.visualViewport.addEventListener("resize", resizeHandler);
    }
    ` : '';

    const contentDocumentData = `<html>
    <head>
      <title>NativeChat</title>
      <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, interactive-widget=resizes-content">
      <script src="${Config.nativeChatScriptUrl}"></script>
      <link href="${Config.nativeChatCssUrl}" rel="stylesheet" type="text/css">
      <style>
      html, body { height: 100%; }
      body { margin: 0; padding: 0; height: 100%; }
      #${NATIVECHAT_CONTAINER_ID} { width: 100%; height: 100%; }
      #${NATIVECHAT_CONTAINER_ID} iframe { width: 100%; height: 100%; border: none; }
      </style>
    </head>
    <body>
      <div id="${NATIVECHAT_CONTAINER_ID}"></div>
    </body>
    <script>${scrollContainerToClientAreaCenterRemediationScriptiOS}    
    const nativechatSettings = ${JSON.stringify(settings)};
    window.NativeChat.load(nativechatSettings);
    </script>
  </html>`;

    if (DeviceHelper.isSamsung()) {
      this.tabOnMobileDevice = window.open('', '_blank') || undefined;

      if (this.tabOnMobileDevice) {

        this.tabOnMobileDevice!.document.write(contentDocumentData);

      }

      return;
    }

    const blob = new Blob([contentDocumentData], { type: 'text/html' });

    const containerURL = window.URL.createObjectURL(blob);

    this.tabOnMobileDevice = window.open(containerURL, '_blank') || undefined;
  }

  private _launcherCloseHandler(isMobile: boolean) {
    if (!isMobile) {
      return this.modal && this.modal.close();
    }

    if (this.tabOnMobileDevice) {
      this.tabOnMobileDevice.close();
    }
  }
}
