import { Config } from './config/config';
import { InlineChat } from './InlineChat';
import { ModalChat } from './ModalChat';
import { NotifyEmitter } from './NotifyEmitter';
import { Display, SDKSettings } from './Settings';

export class NativeChatSDK {
  public chats: { [key: string]: NotifyEmitter } = {};

  public load(settings: SDKSettings) {
    const hasErrors = this.validateSettings(settings);
    if (hasErrors) {
      return;
    }

    this.removeChat(settings.id);

    // inject the web chat version
    settings.chat.webchatVersion = Config.webchatVersion;

    switch (settings.display.mode) {
      case Display.Inline: {
        const chat = new InlineChat(settings.id, settings.origin, settings.chat, settings.display.containerId);
        this.chats[settings.id] = chat;
        break;
      }
      case Display.Modal: {
        const chat = new ModalChat(settings, settings.display.launcher);
        this.chats[settings.id] = chat;
        break;
      }
    }
  }

  public showDebugLog(id: string) {
    this.chats[id].notify({ type: 'show-debug-log', value: true });
  }

  public hideDebugLog(id: string) {
    this.chats[id].notify({ type: 'show-debug-log', value: false });
  }

  public removeChat(id: string): void {
    if (this.chats[id]) {
      this.chats[id].dispose();
      delete this.chats[id];
    }
  }

  private validateSettings(settings: SDKSettings): boolean {
    let hasError = false;

    if (!settings?.chat?.bot?.id) {
      hasError = true;
      console.error(this.getMissingPropertyErrorString('bot id'));
    }

    if (!settings?.chat?.channel?.id) {
      hasError = true;
      console.error(this.getMissingPropertyErrorString('channel id'));
    }

    if (!settings?.chat?.channel?.token) {
      hasError = true;
      console.error(this.getMissingPropertyErrorString('channel token'));
    }

    return hasError
  }

  private getMissingPropertyErrorString(property: string): string {
    return `No ${property} specified in the chat settings.\n For more information of the required properties see https://docs.nativechat.com/docs/1.0/publishing/web.html#chat-required`;
  }
}
