const prefix = 'nativechat';

export const ElementIds = {
  launcher: `${prefix}-launcher`,
  launcherIconClose: `${prefix}-launcher-icon-close`,
  launcherIconOpen: `${prefix}-launcher-icon-open`,
  launcherModal: `${prefix}-launcher-modal`,
  introMessage: `${prefix}-intro-message`,
  introIconClose: `${prefix}-intro-icon-close`
};

export const RtlLocales = ['ar'];
