export class DeviceHelper {
    public static isAndroid(): boolean {
        return navigator.userAgent.match(/Android/i) != null;
    }

    public static isBlackBerry(): boolean {
        return navigator.userAgent.match(/BlackBerry/i) != null;
    }
    public static isiOS(): boolean {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
    }

    public static isOpera(): boolean {
        return navigator.userAgent.match(/Opera Mini/i) != null;
    }

    public static isWindows(): boolean {
        return navigator.userAgent.match(/IEMobile/i) != null;
    }

    public static isSamsung(): boolean {
        return navigator.userAgent.match(/Samsung/i) != null;
    }

    public static isMobile(): boolean {
        return (DeviceHelper.isAndroid() || DeviceHelper.isBlackBerry() || DeviceHelper.isiOS() || DeviceHelper.isOpera() || DeviceHelper.isWindows() || DeviceHelper.isSamsung());
    }

    public static isChromeiOS(): boolean {
        return navigator.userAgent.match(/CriOS/i) != null;
    }
}
